<template>
  <MobileFilter
    :visible="mobileFilterVisible"
    @closeFilter="mobileFilterVisible = $event"
  />
  <div class="w-100 relative overflow-hidden">
    <div class="absolute z-0 left-0 right-0 top-0 bottom-0 flex flex-row justify-between items-center">
      <img class="lg:w-auto w-full absolute left-0 top-0" src="/images/map-right.png"/>
      <img class="lg:w-auto absolute right-0 bottom-36" src="/images/dots.png"/>
    </div>
    <div class="container mx-auto px-0 py-6 z-10 relative flex flex-row">
      <div class="lg:w-3/12 hidden lg:block pr-3">
        <LeftFilter />
      </div>
      <div class="lg:w-9/12 pl-3 w-full pr-3 lg:pr-0">
       <div class="flex lg:flex-row flex-col-reverse w-full justify-between mb-6 items-stretch">
         <div class="flex h-11 flex-grow flex-shrink overflow-x-scroll lg:overflow-auto w-full lg:w-1/3 items-stretch lg:mr-2">
            <button @click="clearAll" v-if="this.filterKeys.length > 0" type="button"
              class="border text-sm px-3 mr-3 flex-shrink-0 bg-blue text-white flex justify-center items-center">
              <i class="fa fa-redo-alt mr-2"></i> Clear All
            </button>
            <button type="button" v-for="(item, index) in filterKeys" :key="index" @click="removeFilter(index)"
              class="border text-sm px-3 mr-3 flex-shrink-0 bg-gray-lighter text-black-lighter flex justify-center items-center">
            <i class="fa fa-times mr-2"></i> {{ filters[item] }}
            </button>
          </div>

          <div class="flex  lg:w-1/3 w-full  flex-row  mb-6 lg:mb-0 lg:hidden">


<!--            <div class="rounded-full bg-gray-100 w-full p-1 flex items-center mb-2 mt-4 relative">-->
<!--              <div class="absolute w-1/2 bottom-0 top-0 p-1 transition-all flex"-->
<!--                   :class="{ 'left-1/2': isPrice, 'left-0': !isPrice}">-->
<!--                <div class="bg-blue rounded-full shadow-sm w-full h-full" style="color: white"></div>-->
<!--              </div>-->
<!--              <div @click="togglePrice"-->
<!--                   class="w-1/2 text-center py-2 text-sm relative cursor-pointer"-->
<!--                   :class="{ 'text-white':!isPrice }">-->
<!--                Finance-->
<!--              </div>-->
<!--              <div @click="togglePrice"-->
<!--                   class="py-2 w-1/2 text-sm text-center relative cursor-pointer"-->
<!--                   :class="{ 'text-white':isPrice }">-->
<!--                Cash-->
<!--              </div>-->
<!--            </div>-->


          </div>

          <div class="flex h-11 lg:w-1/3 w-full items-stretch flex-row-reverse lg:flex-row mb-6 lg:mb-0">


            <button  @click="viewAsList" type="button" class="border w-11 flex-shrink-0 lg:flex hidden bg-white justify-center items-center">
              <i :class=" resultViewType == 'list' ? 'lg:opacity-100' : 'lg:opacity-20'" class="icon small list"></i>
            </button>
            <button  @click="viewAsGrid" type="button" class="border w-11 flex-shrink-0 bg-white hidden lg:flex justify-center items-center">
              <i :class=" resultViewType == 'grid' ? 'lg:opacity-100' : 'lg:opacity-10'" class="icon small thumbnail"></i>
            </button>
            <SelectInput
                :value="params.sort_cars"
                :options="storeFilters['sortCars']"
                @valueChange="(value)=> paramSelected('sort_cars', value)"
                class="lg:ml-6 flex-grow lg:flex-grow-0"
            />

            <button @click="showMobileFilter" type="button"
                    class="border flex-shrink-0  mr-4 px-3 lg:hidden bg-blue text-white flex justify-center items-center" style="width: 55%;">
              <i class="icon small filter mr-2"></i> Filters
              <div v-if="filterCount() > 0" class="bg-red-600 w-6 h-6 text-sm ml-2 text-white flex justify-center items-center rounded-full">
                {{filterCount()}}
              </div>
            </button>

          </div>


        </div>
        <div class="w-full pb-8">
          <SearchResultList :type="resultViewType" />
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import LeftFilter from "../components/LeftFilter.vue";
import MobileFilter from "../components/MobileFilter.vue";
import SearchResultList from "../components/SearchResultList.vue";
import SelectInput from "../components/SelectInput.vue";

export default {
  name: "Search Result Section",
  components: {
    SelectInput,
    SearchResultList,
    MobileFilter,
    LeftFilter,
  },
  methods: {
    clearAll() {
      this.filterKeys = [];
      this.$store.dispatch("fetchDeals", {
        ...this.defaultParams,
      });
    },
    removeFilter(index) {
      const filter = this.filterKeys[index];

      if (filter == "cat_category") {
        this.searchedParams["cat_category"] = "";
        this.searchedParams["make"] = "";
        this.searchedParams["model"] = "";
        this.searchedParams["series"] = "";
      }

      if (filter == "make") {
        this.searchedParams["make"] = "";
        this.searchedParams["model"] = "";
        this.searchedParams["series"] = "";
      }
      if (filter == "model") {
        this.searchedParams["model"] = "";
        this.searchedParams["series"] = "";
      }
      if (filter == "series") {
        this.searchedParams["series"] = "";
      }

      this.searchedParams[filter] = this.defaultParams[filter];

      this.$store.dispatch("fetchDeals", { ...this.searchedParams });
    },
    viewAsGrid() {
      this.resultViewType = "grid";
    },
    viewAsList() {
      this.resultViewType = "list";
    },
    showMobileFilter() {
      this.mobileFilterVisible = true;
    },
    prepareFilters(params) {
      this.filterKeys = [];
      for (const key in params) {
        if (params[key] && key != "fair_userid" && key != "sort_cars" && key != "categories" && key != "user_id") {
          let value = params[key];
          // skip if its all or any as they are defaults
          if (
            typeof value === "string" &&
            (value.toLowerCase() == "all" || value.toLowerCase() == "any")
          ) {
            continue;
          }

          // skip if they are defaults
          if (value == this.defaultParams[key]) {
            continue;
          }

          if (key == "is_price") {
            continue;
          }

          this.filterKeys.push(key);
          value =
            this.getFilterLabel(key) +
            " " +
            (Array.isArray(value) ? value.join(" - ") : value);

          this.filters[key] = value;
        }
      }
    },
    getFilterLabel(key) {
      if (key == "budget_value") {
        return "Budget ($)";
      }
      if (key == "year_value") {
        return "Year";
      }
      return this.toTitleCase(key);
    },
    paramSelected(key, value) {
      this.params[key] = value;
      this.gotoSearch();
    },
    togglePrice() {
      this.isPrice = !this.isPrice;
      this.gotoSearch();
    },
    gotoSearch() {

      this.params["min_budget"] = this.value[0];
      this.params["max_budget"] = this.value[1];
      this.params["from_year"] = this.yearValue[0];
      this.params["to_year"] = this.yearValue[1];
      this.params["odometer_min"] = this.odometerValue[0];
      this.params["odometer_max"] = this.odometerValue[1];
      this.params["min_emi"] = this.emiValue[0];
      this.params["max_emi"] = this.emiValue[1];
      this.params["is_price"] = this.isPrice;

      this.loadDeals({ ...this.params });
      if (this.$route.name != "Search") {
        this.$router.push("/search");
      }
    },
    loadDeals(params) {
      this.$store.dispatch("fetchDeals", params);
    },
    setParams(value) {

      if (!this.value) {
        this.value = [1000, 100000];
      }

      if (value["min_budget"] && value["max_budget"]) {
        this.value = [value["min_budget"], value["max_budget"]];
      }

      if (!this.yearValue) {
        this.yearValue = [2008, 2021];
      }

      if (value["from_year"] && value["to_year"]) {
        this.yearValue = [value["from_year"], value["to_year"]];
      }

      if (!this.odometerValue) {
        this.odometerValue = [1000, 50000];
      }
      if (value["odometer_min"] && value["odometer_max"]) {
        this.odometerValue = [value["odometer_min"], value["odometer_max"]];
      }
      if (!this.emiValue) {
        this.emiValue = [0, 2000];
      }
      if (value["min_emi"] >= 0 && value["max_emi"]) {
        this.emiValue = [value["min_emi"], value["max_emi"]];
      }

      console.log(this.emiValue, [value["min_emi"], value["max_emi"]]);

      this.isPrice = value["is_price"];
    },
    toTitleCase(str) {
      str = str.replace("_", " ");
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    filterCount() {
      return this.filterKeys.length;
    },
    isMobile() {
      if (window.innerWidth <= 768){
        this.toggleFilter = true
      }
      return window.innerWidth <= 768;
    },
    myEventHandler() {
      if (this.isMobile()) {
        this.viewAsGrid();
      }
    },
  },
  computed: {
    ...mapGetters({
      searchedParams: "searchedParams",
      defaultParams: "defaultParams",
      params: "searchParams",
      storeFilters: "filters",
    }),
  },
  watch: {
    searchedParams(newValue) {
      this.prepareFilters(newValue);
    },
  },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.prepareFilters(this.$store.state.searchedParams);
    this.setParams(this.params);
    console.log(this.filterKeys);
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  data() {
    return {
      mobileFilterVisible: false,
      toggleFilter: false,
      resultViewType: "grid",
      filters: {},
      filterKeys: [],
      marks: (val) => val % 1000 === 0,
      value: [5000, 8000],
      yearValue: [2010, 2018],
      odometerValue: [1000, 50000],
      emiValue: [0, 2000],
      isPrice: true,
    };
  },
};
</script>
<style src="../assets/slider-theme.css"></style>
