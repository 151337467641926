<template>
  <div>
    <div v-if="isLoading"
         class="
      fixed
      top-0
      left-0
      bottom-0
      right-0
      bg-white bg-opacity-90
      flex
      justify-center
      items-center
      z-100
    ">
      <img width="350"
           height="auto"
           src="/images/icons/fairpy-loader.gif"
           alt="Fairpy Loading"/>
    </div>
    <div class="hidden lg:block">
      <div class="flex-row p-1 border bg-white transition-all shadow-lg-dark hover:shadow-xl relative flex">
        <div class="w-80 relative flex-shrink-0">
          <div class="absolute top-2 left-2 z-10">
            <p class="rounded-sm px-2 py-1 text-white bg-blue text-xs bg-opacity-90">Sample Photo</p>
          </div>
          <img class="absolute object-contain w-full h-full"
               :src="deal.image"/>
        </div>
        <div class="flex flex-grow flex-col p-4 px-6 text-black-light">
          <div class="flex flex-row justify-between items-stretch">
            <div class="flex flex-col justify-between">
              <h2 class="font-semibold text-left">{{ deal.title }}</h2>
              <p class="text-sm mb-2 text-black-lighter text-left overflow-ellipsis whitespace-nowrap overflow-hidden">
                {{ deal.series }}
              </p>
              <div class="flex flex-row justify-between items-center text-black-lighter">
                <div class="flex flex-row items-center pt-1 text-xs mr-4">
                  <i class="icon small miles mr-2"></i> {{ deal.miles }} miles
                </div>
                <div class="flex flex-row items-center pt-1 text-xs capitalize">
                  <i class="icon small title mr-2"></i> {{ deal.type }}
                </div>
              </div>
            </div>

            <div class="flex flex-col items-end">
              <div v-if="!isFinance()"
                   class="text-blue flex leading-3 items-end relative text-right flex-grow">
                <span class="text-xs mr-2 text-black-light">Purchase Price</span>
                <p class="font-semibold text-xl">${{ deal.bid.toLocaleString() }}</p>
              </div>
              <div v-if="isFinance()"
                   class="text-blue flex leading-3 items-end relative text-right flex-grow">
                <span class="text-xs mr-2 text-black-light">48 Months</span>
                <p v-if="deal.monthly_payments"
                   class="text-sm">
                  <span class="font-semibold text-xl"> ${{
                      deal.monthly_payments.toLocaleString('en-US', {maximumFractionDigits: 0})
                    }}</span> /mo</p>
                <p v-if="!deal.monthly_payments"
                   class="font-semibold text-xl">-</p>
              </div>
              <div class="text-right leading-3">
                <div class="text-black-lighter cursor-pointer flex-row flex items-center text-xs"
                     @click="toggleKelly"
                     @mouseover="showKelly = true"
                     @mouseleave="showKelly = false">
                  Savings*
                  <span class="font-semibold ml-2 text-base">
                     <span v-if="isFinance()"> ${{ deal.finance_savings.toLocaleString() }}</span>
               <span v-if="!isFinance()"> ${{ deal.savings.toLocaleString() }}</span>
                  </span>
                  <i class="icon small info ml-1"></i>

                  <div v-if="showKelly"
                       class="absolute top-4 right-4 rounded-sm border bg-gray-lighter bg-opacity-95 z-99">
                    <div class="w-full relative">
                      <div
                          class="h-full text-sm border-2 border-black-light rounded p-5 flex flex-col shadow-lg-dark justify-center items-center">
                        <img src="/images/icons/badge.png"
                             class="mx-auto w-20"/>
                        <div class="flex flex-row justify-between mx-auto mb-4 mt-4 w-full">
                          <div class="text-black-lighter">Retail Value</div>
                          <div class="ml-4 font-medium">${{ deal.bookValue.toLocaleString() }}</div>
                        </div>
                        <div class="flex flex-row justify-between mx-auto mb-4 mt-4 w-full">
                          <div class="text-black-lighter">Cost of Car</div>
                          <div class="ml-4 font-medium">${{ deal.bid.toLocaleString() }}</div>
                        </div>
                        <div class="flex flex-row justify-between mx-auto mb-4 mt-4 w-full">
                          <div class="text-black-lighter">Sales Tax</div>
                          <div class="ml-4 font-medium">${{ deal.sales_tax.toLocaleString() }}</div>
                        </div>
                        <div
                            class="bg-black-light w-full text-center font-medium text-base text-white px-5 py-3 rounded">
                          <span class="">You Saved</span>
                          <span v-if="isFinance()"> ${{ deal.finance_savings.toLocaleString() }}</span>
                          <span v-if="!isFinance()"> ${{ deal.savings.toLocaleString() }}</span>
                        </div>
                      </div>
                      <i class="fa fa-times absolute right-3 top-3 text-black-lighter"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="my-4"/>
          <div class="flex flex-row items-end justify-between">
            <div class="flex flex-col text-xs text-black-lighter">
              <p class="mb-2 capitalize">Car Details: {{ deal.details }}</p>
              <p v-if="!isFinance()"
                 class="mb-2">Offer Valid Until: {{ this.offerValidDate }}</p>
              <p v-if="isFinance()"
                 class="mb-2">Down Payment:
                ${{ deal.down_payment.toLocaleString('en-US', {maximumFractionDigits: 0}) }}</p>
              <p>Car arrives: {{ getNextDate(14) }}</p>
            </div>
            <div class="bg-white shadow-md flex items-center justify-center p-3 rounded-full">
              <i :class="isFavourite ? 'selected' : ''"
                 class="icon md wishlist cursor-pointer"
                 @click.stop="setFavourite()"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block lg:hidden">
      <div class="flex flex-row w-full items-stretch border bg-white transition-all shadow-lg hover:shadow-xl relative">
        <div class="w-5/12 flex-shrink-0 relative block">
          <div class="absolute top-2 left-2 z-50">
            <p class="rounded-sm px-2 py-1 text-white bg-blue text-xs bg-opacity-90">Sample Photo</p>
          </div>
          <img class="absolute object-cover object-left w-full h-full"
               :src="deal.image"/>
        </div>
        <div class="flex flex-col p-3 text-black-light flex-grow">
          <div class="flex flex-row items-start justify-between">
            <h2 class="font-semibold text-left text-lg leading-tight">
              {{ deal.title }}
            </h2>
            <i v-if="!isFavourite"
               class="far fa-heart text-xl text-blue cursor-pointer ml-2"
               @click.stop="setFavourite()"></i>
          </div>
          <p class="text-sm mb-2 text-black-lighter text-left overflow-ellipsis whitespace-nowrap overflow-hidden">
            {{ deal.series }}
          </p>
          <div class="flex flex-row items-center text-black-lighter mb-2 mt-2">
            <div class="flex flex-row items-center text-sm mr-3">
              {{ deal.miles }} miles
            </div>
            <div class="flex flex-row items-center text-sm capitalize">
              {{ deal.type }}
            </div>
          </div>
          <hr/>
          <div class="flex pt-4 flex-col text-xs text-black-lighter">
            <p class="mb-1 capitalize">Car Details: {{ deal.details }}</p>
            <p v-if="!isFinance()"
               class="mb-1">Offer Valid Until: {{ this.getAddDays(2) }}</p>
            <p v-if="isFinance()"
               class="mb-1">Down Payment: ${{ deal.down_payment.toLocaleString('en-US', {maximumFractionDigits: 0}) }}
            </p>

            <p>Shipping Cost: ${{ deal.shipping_fee.toLocaleString() }}</p>
          </div>
          <hr class="hidden lg:block"/>
          <div class="flex flex-row mt-3 items-center justify-between">
            <div v-if="!isFinance()"
                 class="text-blue leading-3 relative flex-grow">
              <p class="font-semibold lg:text-xl text-2xl">${{ deal.bid.toLocaleString() }}</p>
              <span class="text-xs mt-1 text-black-light">Purchase Price</span>
            </div>
            <div v-if="isFinance()"
                 class="text-blue leading-3 relative flex-grow">
              <p v-if="deal.monthly_payments"
                 class="font-semibold lg:text-xl text-2xl">
                ${{ deal.monthly_payments.toLocaleString('en-US', {maximumFractionDigits: 0}) }}</p>
              <p v-if="!deal.monthly_payments"
                 class="font-semibold lg:text-xl text-2xl">-</p>
              <span class="text-xs mt-1 text-black-light">Purchase Price</span>
            </div>
            <div class="text-right leading-3">
              <p class="font-normal text-lg">
                <span v-if="isFinance()"> ${{ deal.finance_savings.toLocaleString() }}</span>
                <span v-if="!isFinance()"> ${{ deal.savings.toLocaleString() }}</span>

              </p>
              <div class="text-black-lighter cursor-pointer flex-row lg:flex hidden items-center text-xs mt-1"
                   @mouseenter="showKelly = true"
                   @mouseleave="showKelly = false">
                Savings*

                <i class="icon small info ml-1"></i>

                <div :class="showKelly ? 'block' : 'hidden'"
                     class="absolute bottom-2 right-2 rounded-sm border bg-gray-lighter bg-opacity-95">
                  <div class="text-sm flex flex-col items-center p-2 px-5 text-black-light relative">
                    <p class="z-10">Retail Value</p>
                    <p class="z-10">${{ deal.bookValue.toLocaleString() }}*</p>
                    <i class="icon w-10 h-10 i-icon absolute -left-2 bottom-0 z-0"></i>
                    <i class="fa fa-times absolute right-1 top-1 text-black-lighter">
                    </i>
                  </div>
                </div>
              </div>

              <!-- Remove Duplicate -->

              <div class="text-black-lighter cursor-pointer flex-row lg:hidden flex items-center text-xs mt-1"
                   @click="toggleKelly">
                Savings* <i class="icon small info ml-1"></i>

                <div :class="showKelly ? 'block' : 'hidden'"
                     class="absolute bottom-2 right-2 rounded-sm border bg-gray-lighter bg-opacity-95">
                  <div class="text-sm flex flex-col items-center p-2 px-5 text-black-light relative">
                    <p class="z-10">Retail Value</p>
                    <p class="z-10">${{ deal.bookValue.toLocaleString() }}*</p>
                    <i class="icon w-10 h-10 i-icon absolute -left-2 bottom-0 z-0"></i>
                    <i class="fa fa-times absolute right-1 top-1 text-black-lighter">
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import {useToast} from "vue-toastification";
import {mapGetters} from "vuex";

export default {
  props: {
    deal: Object,
  },
  components: {},
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "searchParams",
    }),
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  data() {
    return {
      showKelly: false,
      isFavourite: false,
      isLoading: false,
      offerValidDate: ''
    };
  },
  mounted() {
    this.getAddDays();
  },
  methods: {
    getAddDays() {
      var options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
      };
      const validDate = new Date();
      validDate.setDate(validDate.getDate() + 2);
      console.log(validDate);
      let validDateFormat = validDate.toLocaleDateString("en-US", options);
      let validFormat = validDateFormat.split(" ");
      let validFormatDate = validFormat[1].replace(/,/g, '') + '-' + validFormat[0] + '-' + validFormat[2];
      this.offerValidDate = validFormatDate;
    },
    getNextDate(daysToAdd = 0) {
      var options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
      };
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0');
      let yy = today.getFullYear();


      today = mm + '-' + dd + '-' + yy;

      this.date = today;

      if (this.deal.date) {
        today = this.deal.date;
      }
      if (today && Date.parse(today)) {
        const date = new Date(Date.parse(today));

        const newDate = new Date(Date.parse(date.toDateString()));
        newDate.setDate(date.getDate() + daysToAdd);
        let getDate = newDate.toLocaleDateString("en-US", options);
        let dateParts = getDate.split(" ");
        let formatDate = dateParts[1].replace(/,/g, '') + '-' + dateParts[0] + '-' + dateParts[2];
        return formatDate;
      }
    },
    isFinance() {
      return !this.searchParams.is_price;
    },
    toggleKelly() {
      this.showKelly = !this.showKelly;
    },
    dealImgJpg(value) {
      return value.substring(0, value.indexOf(".") + 1) + "jpg";
    },
    setFavourite() {
      if (!this.loggedIn) {
        this.emitter.emit("showLogin", true);
        return;
      }

      this.isFavourite = !this.isFavourite;
      if (this.isFavourite) {
        this.updateFavouriteToServer();
      }
    },
    updateFavouriteToServer() {
      this.$store
          .dispatch("setFavourite", this.deal.id)
          .then((res) => {
            console.log(res);
            this.toast.success("Added to Wishlist Successfully");
          })
          .catch((error) => {
            console.log(error);
            this.toast.success("Sorry Unable to add to wishlist");
          });
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 768px) {
  .object-custom-position {
    object-position: 0px 0px;
  }
}

.object-custom-position {
  object-position: 0px -80px;
}
</style>