<template>
  <div class="mb-4 text-sm font-semibold">Total available cars {{this.deals.length}}</div>
<div ref="scrollContainer">
  <div
    v-if="type == 'grid'"
    class="w-full grid-cols-1 lg:grid-cols-3 grid gap-8" 
  >
    <SearchResultItem
      class="w-full"
      :deal="item"
      @click.prevent="gotoDetails(item)"
      v-for="item in loadedDeals"
      v-bind:key="item.id"
    />
  </div>
  <div v-if="type != 'grid'" class="w-full grid-cols-1 grid gap-8">
    <SearchResultItemHorizontal
      class="w-full"
      @click.prevent="gotoDetails(item)"
      :deal="item"
      v-for="item in loadedDeals"
      v-bind:key="item.id"
    />
  </div>
</div>
<div ref="sentinel" class="sentinel flex justify-center mt-6">
      <button @click="loadNext()" v-if="showLoadMore()" type="button" class="border border-blue text-blue p-2 px-6">Load More</button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SearchResultItem from "./SearchResultItem.vue";
import SearchResultItemHorizontal from "./SearchResultItemHorizontal.vue";
// import axios from "axios";

export default {
  name: "CarDeals",
  components: {
    SearchResultItem,
    SearchResultItemHorizontal,
  },
  props: {
    type: String,
  },
  computed: {
    ...mapGetters({
      deals: "deals",
      isLoading: "isLoading",
    }),
  },
  watch: {
    deals() {
      this.pageIndex = -1;
      this.loadedDeals = [];
      this.loadNext();
    },
  },
  data: function () {
    return {
      params: {
        make: "",
        model: "",
        series: "",
      },
      pageIndex: 0,
      limit: 9,
      loadedDeals: [],
      lastLoadTime: 0,
      scrollPos: 0,
    };
  },
  mounted() {
    console.log(this.deals.length)
    console.log('Available Cars ---')
    if (this.loadedDeals.length == 0 && this.deals.length > 0) {
      this.loadNext();
    }
    if (this.deals.length == 0) {
      this.$store.dispatch("fetchDealsWithLastParams");
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    showLoadMore() {
      return this.loadedDeals && this.loadedDeals.length < this.deals.length;
    },
    loadDeals(params) {
      this.$store.dispatch("fetchDeals", params);
    },
    gotoDetails(item) {
      this.$store.dispatch("setSelectedDealId", item.id);
      this.$router.push({ name: "Details", params: { vin: item.id } });
    },
    loadNext() {
      var initialHeight = document.documentElement.scrollTop;
      this.pageIndex++;
      const start = this.pageIndex * this.limit;

      this.loadedDeals = [...this.deals.slice(0, start + this.limit)];

      this.$nextTick(() => {
        if (initialHeight != 0) {
          window.scrollTo(0, initialHeight);
        }
      });
    },

    handleScroll() {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.offsetHeight - 100;
      this.scrollPos = document.documentElement.scrollTop;
      if (bottomOfWindow && !this.isLoading) {
        if (this.showLoadMore()) {
          this.$store.dispatch("setIsLoading", true);
          window.setTimeout(() => {
            this.$store.dispatch("setIsLoading", false);
            this.loadNext();
          }, 1500);
        }
      }
    },
  },
};
</script>
<style></style>
