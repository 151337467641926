<template>
  <TopBar />
  <NavBar />
  <SearchResultSection />
  <Footer />
</template>

<script>
import TopBar from "../components/TopBar.vue";
import NavBar from "../components/NavBar.vue";
import Footer from "../components/Footer.vue";
import SearchResultSection from "../components/SearchResultSection.vue";

export default {
  name: "Home",
  components: {
    TopBar,
    NavBar,
    Footer,
    SearchResultSection,
  },
};
</script>

<style></style>
