<template>
  <div class="p-6 flex flex-col border bg-white">
    <button type="button"
            @click="gotoSearch"
            class="bg-blue text-white w-full p-2 py-3 text-sm uppercase mb-6">
      Submit Filter
    </button>

    <button type="button"
            @click="saveSearch"
            class="border-2 border-blue-dark font-bold w-full p-2 py-3 text-sm uppercase mt-2 mb-8">
      Save Search
    </button>

    <label class="uppercase text-xs mb-2 text-black-lighter">
      Select Categories
    </label>
    <SelectInput :value="params.categories"
                 :options="filters['categories']"
                 :selected-value="params.categories = this.params.car_category"
                 @valueChange="selectedCategory"
                 class="flex-grow mb-3"
                 placeholder="Categories"/>


    <label class="uppercase text-xs mb-2 text-black-lighter">
      Select Make
    </label>
    <SelectInput :value="params.make"
                 :options="makes"
                 @valueChange="updateMake"
                 class="flex-grow mb-3"
                 placeholder="Make" />

    <label class="uppercase text-xs mb-2 text-black-lighter">Select Model</label>
    <SelectInput :value="params.model"
                 :options="models"
                 @valueChange="updateModel"
                 class="flex-grow mb-3"
                 placeholder="Model" />

    <label class="uppercase text-xs mb-2 text-black-lighter">Odometer</label>
    <div class="relative select-wrapper w-full flex flex-col bg-white mb-3 text-sm">
      <div class="flex flex-row w-full justify-end items-center mb-2">
        <div class="flex flex-row items-center flex-grow-0 w-full">
          <input @keyup="minOdoValueChange(odometerValue[0])"
                 class="flex-grow min-w-0 text-right border px-2 py-1 rounded"
                 name="min_budget"
                 v-model="odometerValue[0]" />
          <div>&nbsp;-&nbsp;</div>
          <input @keyup="maxOdoValueChange(odometerValue[0])"
                 class="flex-grow min-w-0 text-right border px-2 py-1 rounded"
                 name="max_budget"
                 v-model="odometerValue[1]" />
        </div>
      </div>
      <vue-slider :interval="1000"
                  :min="0"
                  :max="100000"
                  v-model="odometerValue"></vue-slider>
      <div class="flex flex-row justify-between">
        <label>0 mi</label>
        <label>100,000 mi</label>
      </div>
    </div>


    <label class="uppercase text-xs mb-2 text-black-lighter mt-2">Choose Year</label>
    <div class="relative select-wrapper w-full flex flex-col bg-white mb-3 text-sm">
      <div class="flex flex-row w-full justify-end items-center mb-2">
        <div class="flex flex-row items-center flex-grow-0 w-full">
          <input @keyup="minYearValueChange(yearValue[0])"
                 class="flex-grow min-w-0 text-right border px-2 py-1 rounded"
                 name="from_year"
                 v-model="yearValue[0]" />
          <div>&nbsp;-&nbsp;</div>
          <input @keyup="maxYearValueChange(yearValue[1])"
                 class="flex-grow min-w-0 text-right border px-2 py-1 rounded"
                 name="to_year"
                 v-model="yearValue[1]" />
        </div>
      </div>
      <vue-slider :step="1"
                  :min="2008"
                  :max="2022"
                  v-model="yearValue"></vue-slider>
      <div class="flex flex-row justify-between">
        <label>2008</label>
        <label>2022</label>
      </div>
    </div>
<!--    <div class="rounded-full bg-gray-100 w-full p-1 flex items-center mb-2 mt-4 relative">-->
<!--      <div class="absolute w-1/2 bottom-0 top-0 p-1 transition-all flex"-->
<!--           :class="{ 'left-1/2': isPrice, 'left-0': !isPrice}">-->
<!--        <div class="bg-white rounded-full shadow-sm w-full h-full"></div>-->
<!--      </div>-->
<!--      <div @click="togglePrice"-->
<!--           class="w-1/2 text-center py-2 text-sm relative cursor-pointer"-->
<!--           :class="{ 'text-blue':isPrice }">-->
<!--        Finance-->
<!--      </div>-->
<!--      <div @click="togglePrice"-->
<!--           class="py-2 w-1/2 text-sm text-center relative cursor-pointer"-->
<!--           :class="{ 'text-blue':!isPrice }">-->
<!--        Cash-->
<!--      </div>-->
<!--    </div>-->
    <div v-if="isPrice"
         class="relative select-wrapper w-full flex flex-col bg-white mb-3 text-sm">
      <label class="uppercase text-xs mb-2 text-black-lighter mt-2">Your Budget</label>
      <div class="flex flex-row w-full justify-between items-center mb-2">
        <div class="flex flex-row items-center flex-grow-0 w-full">
          <div class="relative flex-grow min-w-0">
            <div class="absolute left-0 top-0 bottom-0 flex items-center justify-center pl-2">$</div>
            <input class="border w-full pr-2 py-1 pl-4 rounded text-right"
                   @keyup="minValueChange(value[0])"
                   name="min_budget"
                   v-model="value[0]" />
          </div>
          <div>&nbsp;-&nbsp;</div>
          <div class="relative flex-grow min-w-0">
            <div class="absolute left-0 top-0 bottom-0 flex items-center justify-center pl-2">$</div>
            <input class="border w-full pr-2 py-1 pl-4 rounded text-right"
                   @keyup="maxValueChange(value[1])"
                   name="max_budget"
                   v-model="value[1]" />
          </div>
        </div>
      </div>
      <vue-slider :interval="500"
                  :min="1000"
                  :max="200000"
                  v-model="value"></vue-slider>
      <div class="flex flex-row justify-between">
        <label>$1,000</label>
        <label>$200,000</label>
      </div>
    </div>
    <div v-if="!isPrice"
         class="relative select-wrapper w-full flex flex-col bg-white mb-3 text-sm">
      <label class="uppercase text-xs mb-2 text-black-lighter mt-2">Monthly Budget</label>
      <div class="flex flex-row w-full justify-between items-center mb-2">
        <div class="flex flex-row items-center flex-grow-0 w-full">
          <div class="relative flex-grow">
            <div class="absolute left-0 top-0 bottom-0 flex items-center justify-center pl-2">$</div>
            <input class="w-full border pr-2 py-1 pl-4 rounded text-right"
                   @keyup="minEmiValueChange(emiValue[0])"
                   name="min_emi_budget"
                   v-model="emiValue[0]" />
          </div>
          <div>&nbsp;-&nbsp;</div>
          <div class="relative flex-grow">
            <div class="absolute left-0 top-0 bottom-0 flex items-center justify-center pl-2">$</div>
            <input class="w-full border pr-2 py-1 pl-4 rounded text-right"
                   @keyup="maxEmiValueChange(emiValue[1])"
                   name="max_emi_budget"
                   v-model="emiValue[1]" />
          </div>
        </div>
      </div>
      <vue-slider :interval="100"
                  :min="0"
                  :max="2000"
                  v-model="emiValue"></vue-slider>
      <div class="flex flex-row justify-between">
        <label>$0</label>
        <label>$2,000</label>
      </div>
    </div>
    <button type="button"
            @click="gotoSearch"
            class="bg-blue text-white w-full p-2 py-3 text-sm uppercase mt-6">
      Submit Filter
    </button>

    <button type="button"
            @click="saveSearch"
            class="border-2 border-blue-dark font-bold w-full p-2 py-3 text-sm uppercase mt-8">
      Save Search
    </button>
  </div>

  <div class="p-6 flex-col mt-6 bg-white border hidden">
    <label class="uppercase text-xs mb-2 text-black-lighter">
      Select Title
    </label>
    <SelectInput :value="params.title"
                 :options="filters['title']"
                 @valueChange="(value)=> paramSelected('title', value)"
                 class="flex-grow mb-3" />

    <label class="uppercase text-xs mb-2 text-black-lighter">
      Select Body Style
    </label>
    <SelectInput :value="params.bodystyle"
                 :options="filters['bodyStyle']"
                 @valueChange="(value)=> paramSelected('bodystyle', value)"
                 class="flex-grow mb-3" />

    <label class="uppercase text-xs mb-2 text-black-lighter">
      Select Transmission
    </label>
    <SelectInput :value="params.transmission"
                 :options="filters['transmission']"
                 @valueChange="(value)=> paramSelected('transmission', value)"
                 class="flex-grow mb-3" />
    <label class="uppercase text-xs mb-2 text-black-lighter">
      Select Fuel Type
    </label>
    <SelectInput :value="params.fuel_type"
                 :options="filters['fuelType']"
                 @valueChange="(value)=> paramSelected('fuel_type', value)"
                 class="flex-grow mb-3" />
    <label class="uppercase text-xs mb-2 text-black-lighter">
      Select Distance
    </label>
    <div class="flex flex-row h-10 w-100 mb-6">
      <input type="text"
             class="mr-2 w-full border p-2 flex-grow text-sm placeholder-black-light"
             placeholder="Zip Code" />
      <SelectInput :value="params.distance_range"
                   :options="filters['range']"
                   @valueChange="(value)=> paramSelected('distance_range', value)"
                   class="flex-grow mb-3" />
    </div>

    <label class="uppercase text-xs mb-2 text-black-lighter">
      Select Color
    </label>
    <SelectInput :value="params.color"
                 :options="filters['color']"
                 @valueChange="(value)=> paramSelected('color', value)"
                 class="flex-grow mb-3" />
    <div class="grid grid-rows-3 grid-cols-4 gap-3 mb-6">
      <div v-for="(item, index) in colors"
           :key="index"
           :class="colors[index]"
           class="border cursor-pointer w-12 h-12 rounded"></div>
    </div>

    <button type="button"
            @click="gotoSearch"
            class="bg-blue text-white w-full p-2 py-3 text-sm uppercase mt-6 mb-6">
      Submit Filter
    </button>
  </div>
</template>
<script>
import VueSlider from "vue-slider-component";
import { mapGetters } from "vuex";
import SelectInput from "../components/SelectInput.vue";

export default {
  props: {},
  name: "Search Result Section",
  data() {
    return {
      value: [5000, 8000],
      yearValue: [2010, 2018],
      odometerValue: [1000, 50000],
      emiValue: [0, 2000],
      isPrice: true,
      colors: [
        "bg-white",
        "bg-black-light",
        "bg-black-lighter",
        "bg-gray-600",
        "bg-blue-dark",
        "bg-red-800",
        "bg-brown-light",
        "bg-orange",
        "bg-green-600",
        "bg-brown",
        "bg-yellow-300",
      ],

      filterMenus: [
        "Categories",
        "Title",
        "Bid Range",
        "Make",
        "Model",
        "Year",
        "Odometer",
        "Body Style",
        "Transmission",
        "Fuel Type",
        "Distance",
        "Color",
      ],
      makes: [],
      models: [],
      series: [],
      gettingLocation: false,
    };
  },
  computed: {
    ...mapGetters({
      makeObjects: "makes",
      params: "searchParams",
      filters: "filters",
    }),
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  watch: {
    params(value) {
      this.setParams(value);
    },
    makeObjects() {
      this.populateMakes();
    },
  },
  components: {
    SelectInput,
    VueSlider,
  },
  methods: {
    togglePrice() {
      this.isPrice = !this.isPrice;
      this.gotoSearch();
    },
    minValueChange(value) {
      if (value < 1000 || isNaN(value)) {
        this.value[0] = 1000;
      }
      this.value = [...this.value];
      this.gotoSearch();
    },
    maxValueChange(value) {
      if (value > 200000 || isNaN(value)) {
        this.value[1] = 200000;
      }
      this.value = [...this.value];
      this.gotoSearch();
    },
    minEmiValueChange(value) {
      if (value < 0 || isNaN(value)) {
        this.emiValue[0] = 0;
      }
      this.emiValue = [...this.emiValue];
      this.gotoSearch();
    },
    maxEmiValueChange(value) {
      if (value > 200000 || isNaN(value)) {
        this.emiValue[1] = 2000;
      }
      this.emiValue = [...this.emiValue];
      this.gotoSearch();
    },
    minYearValueChange(value) {
      //  if (value < 2008 || isNaN(value)) {
      //   this.yearValue[0] = 2008;
      // }
      if (value >= this.yearValue[1] || isNaN(value)) {
        this.yearValue[0] = this.yearValue[1];
      }
      this.yearValue = [...this.yearValue];
      this.gotoSearch();
    },
    maxYearValueChange(value) {
      if (value > 2022 || isNaN(value)) {
        this.yearValue[1] = 2022;
      }
      // this.yearValue = [...this.yearValue];
      this.gotoSearch();
    },
    minOdoValueChange(value) {
      if (value < 0 || isNaN(value)) {
        this.odometerValue[0] = 0;
      }
      this.odometerValue = [...this.odometerValue];
      this.gotoSearch();
    },
    maxOdoValueChange(value) {
      if (value > 100000 || isNaN(value)) {
        this.odometerValue[1] = 100000;
      }
      this.odometerValue = [...this.odometerValue];
      this.gotoSearch();
    },
    saveSearch(){
      if (!this.loggedIn) {
        this.emitter.emit("showLogin", true);
        return;
      }
      this.params["min_budget"] = this.value[0];
      this.params["max_budget"] = this.value[1];
      this.params["from_year"] = this.yearValue[0];
      this.params["to_year"] = this.yearValue[1];
      this.params["odometer_min"] = this.odometerValue[0];
      this.params["odometer_max"] = this.odometerValue[1];
      this.params["min_emi"] = this.emiValue[0];
      this.params["max_emi"] = this.emiValue[1];
      this.params["is_price"] = this.isPrice;

      this.$store.dispatch("saveSearch", this.params).then(
          (value) => {
            console.log(value);
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );

    },
    gotoSearch() {
      this.params["min_budget"] = this.value[0];
      this.params["max_budget"] = this.value[1];
      this.params["from_year"] = this.yearValue[0];
      this.params["to_year"] = this.yearValue[1];
      this.params["odometer_min"] = this.odometerValue[0];
      this.params["odometer_max"] = this.odometerValue[1];
      this.params["min_emi"] = this.emiValue[0];
      this.params["max_emi"] = this.emiValue[1];
      this.params["is_price"] = this.isPrice;

      this.loadDeals({
        ...this.params,
      });
      if (this.$route.name != "Search") {
        this.$router.push("/search");
      }
    },
    loadDeals(params) {
      this.$store.dispatch("fetchDeals", params);
    },
    modelsForMake(makeName) {
      this.params.make = makeName;
      this.models = [];
      this.series = [];
      if (!makeName) {
        return;
      }
      const makeObj = this.makeObjects.find(
        (item) => item.name == this.params.make
      );
      this.models = makeObj.models.map((item) => item.name);
    },


    seriesForModel(modelName) {
      this.params.model = modelName;
      this.series = [];
      if (!modelName) {
        return;
      }
      const makeObj = this.makeObjects.find(
        (item) => item.name == this.params.make
      );
      const model = makeObj.models.find(
        (item) => item.name == this.params.model
      );
      this.series = model.series.map((item) => item.name);
    },
    seriesSelected(seriesName) {
      this.params.series = seriesName;
    },

    paramSelected(key, value) {
      this.params[key] = value;
    },

    setParams(value) {
      if (value["make"]) {
        this.modelsForMake(value["make"]);
      }
      if (value["model"]) {
        this.seriesForModel(value["model"]);
      }
      if (value["series"]) {
        this.seriesSelected(value["series"]);
      }

      if (!this.value) {
        this.value = [1000, 100000];
      }

      if (value["min_budget"] && value["max_budget"]) {
        this.value = [value["min_budget"], value["max_budget"]];
      }

      if (!this.yearValue) {
        this.yearValue = [2008, 2021];
      }

      if (value["from_year"] && value["to_year"]) {
        this.yearValue = [value["from_year"], value["to_year"]];
      }

      if (!this.odometerValue) {
        this.odometerValue = [1000, 50000];
      }
      if (value["odometer_min"] && value["odometer_max"]) {
        this.odometerValue = [value["odometer_min"], value["odometer_max"]];
      }
      if (!this.emiValue) {
        this.emiValue = [0, 2000];
      }
      if (value["min_emi"] >= 0 && value["max_emi"]) {
        this.emiValue = [value["min_emi"], value["max_emi"]];
      }

      this.isPrice = value["is_price"];
    },

    selectedCategory(category) {
      this.params.car_category = category;
      this.params.make = "";
      this.params.model = "";
      this.params.series = "";
      this.$store.dispatch("setSearchParams", {
        ...this.params,
      });
      this.gotoSearch();
      this.populateMakes();
    },

    updateMake(make) {
      this.params.make = make;
      this.params.model = "";
      this.params.series = "";
      this.$store.dispatch("setSearchParams", {
        ...this.params,
      });
      this.gotoSearch();
    },
    updateModel(model) {
      this.params.model = model;
      this.params.series = "";
      this.$store.dispatch("setSearchParams", {
        ...this.params,
      });
      this.gotoSearch();
    },
    updateSeries(series) {
      this.params.series = series;
      this.$store.dispatch("setSearchParams", {
        ...this.params,
      });
    },
    populateMakes(){
      this.makes = this.makeObjects.map((item) => item.name);
    }
  },
  mounted() {
    this.populateMakes();
    this.setParams(this.params);
  },
};
</script>
<style scoped></style>