<template>
  <div v-if="visible"
       class="fixed top-0 right-0 left-0 bottom-0 bg-white z-50 lg:hidden flex flex-col">
    <div class="shadow-lg flex p-4 justify-between z-10 relative">
      <div class="flex items-center">
        <button @click="closeFilter">
          <i class="fa fa-arrow-left mr-3"></i> Filters
        </button>

        <button @click="saveSearch" class="ml-14 text-blue-700 font-semibold uppercase">
          Save Search
        </button>
      </div>
      <button @click="clearAll"
              class="text-blue">CLEAR ALL</button>
    </div>
    <div class="flex relative z-0 overflow-y-scroll flex-grow">
      <div class="w-2/6 h-full bg-gray-lighter overflow-y-scroll">
        <div v-for="(item, index) in filterMenus"
             @click="setSelectedFilterMenu(item)"
             :key="index"
             :class="
            item == selectedFilterMenu
              ? 'py-8 border-l-4 border-blue bg-white font-semibold'
              : 'font-normal'
          "
             class="p-3 py-6">
          {{ item }}
        </div>
      </div>
      <div class="w-4/6 h-full overflow-y-scroll">
        <div v-if="selectedFilterMenu == 'Title'"
             id="bid-range-filter"
             class="flex flex-col w-full p-4 py-8 text-black-lighter">
          <div v-for="(item, index) in filters['title']"
               :key="index"
               class="py-4">
            <label class="text-base flex flex-row items-center"><input type="radio"
                     :checked="params['title'] == item['value']"
                     name="title"
                     v-on:change="paramSelected('title',$event.target.value)"
                     class="mr-3 w-4 h-4"
                     :value="item['value']" />
              {{ item['label'] }}</label>
          </div>
        </div>

        <!---- Categories --->

        <div v-if="selectedFilterMenu == 'Categories'"
             id="makes-filter"
             class="flex flex-col w-full p-4 py-8 text-black-lighter">
          <div class="relative w-full mb-4">
            <input placeholder="Search Categories"  class="border p-3 w-full flex-grow" type="text"
                   name="search" id="search-box" />
            <div class="absolute right-0 top-0 bottom-0 flex items-center flex-row mr-3">
              <i class="icon small search"></i>
            </div>
          </div>
          <div v-for="(item, index) in filters['categories']" :key="index" class="py-4">
            <label class="text-base flex flex-row items-center">
              <input type="radio"  v-on:change="selectedCategory($event.target.value)" :checked="params['car_category'] == item['value']"
                 name="categories" class="mr-3 w-4 h-4" :value="item['value']" />
              {{ item['label'] }}</label>
          </div>
        </div>

        <!-- Makes -->
        <div v-if="selectedFilterMenu == 'Make'"
             id="makes-filter"
             class="flex flex-col w-full p-4 py-8 text-black-lighter">
          <div class="relative w-full mb-4">
            <input placeholder="Search Make" class="border p-3 w-full flex-grow" type="text" name="search" id="search-box" />
            <div class="absolute right-0 top-0 bottom-0 flex items-center flex-row mr-3">
              <i class="icon small search"></i>
            </div>
          </div>
          <div v-for="(item, index) in makes" :key="index" class="py-4">
            <label class="text-base flex flex-row items-center">
              <input type="radio" v-on:change="updateMake($event.target.value)" :checked="params['make'] == item"
                     name="makes" class="mr-3 w-4 h-4" :value="item" />
              {{ item }}</label>
          </div>
        </div>
        <!-- Model Filter -->
        <div v-if="selectedFilterMenu == 'Model'"
             id="models-filter"
             class="flex flex-col w-full p-4 py-8 text-black-lighter">
          <div class="relative w-full mb-4">
            <input placeholder="Search Model"
                   class="border p-3 w-full flex-grow"
                   type="text"
                   name="search"
                   id="search-box" />
            <div class="absolute right-0 top-0 bottom-0 flex items-center flex-row mr-3">
              <i class="icon small search"></i>
            </div>
          </div>
          <div v-for="(item, index) in models"
               :key="index"
               class="py-4">
            <label class="text-base flex flex-row items-center"><input type="radio"
                     :checked="params['model'] == item"
                     name="models"
                     class="mr-3 w-4 h-4"
                     v-on:change="updateModel($event.target.value)"
                     :value="item" />
              {{ item }}</label>
          </div>
        </div>
        <div v-if="selectedFilterMenu == 'Series'"
             id="series-filter"
             class="flex flex-col w-full p-4 py-8 text-black-lighter">
          <div class="relative w-full mb-4">
            <input placeholder="Search Series"
                   class="border p-3 w-full flex-grow"
                   type="text"
                   name="search"
                   id="search-box" />
            <div class="absolute right-0 top-0 bottom-0 flex items-center flex-row mr-3">
              <i class="icon small search"></i>
            </div>
          </div>
          <div v-for="(item, index) in series"
               :key="index"
               class="py-4">
            <label class="text-base flex flex-row items-center"><input type="radio"
                     :checked="params['series'] == item['value']"
                     name="series"
                     class="mr-3 w-4 h-4"
                     v-on:change="updateSeries($event.target.value)"
                     :value="item" />
              {{ item }}</label>
          </div>
        </div>
        <!-- Year Range -->
        <div v-if="selectedFilterMenu == 'Year'"
             id="bid-range-filter"
             class="flex flex-col w-full p-4 py-8 text-black-lighter">
          <div v-for="(item, index) in years"
               :key="index"
               class="py-4">
            <label class="text-base flex flex-row items-center"><input type="checkbox"
                     class="mr-3 w-4 h-4"
                     :value="index" />
              {{ item }}</label>
          </div>
          <label class="text-base mb-2 mt-8 text-black-lighter">
            Choose Range Below
          </label>
          <div class="relative select-wrapper w-full flex flex-col bg-white mb-3 text-sm">
            <div class="flex flex-row w-full justify-end items-center mb-2">
              <div class="flex flex-row items-center flex-grow-0 w-full">
                <input @keyup="minYearValueChange(yearValue[0])"
                       class="flex-grow min-w-0 text-right border px-2 py-1 rounded"
                       name="min_year"
                       v-model="yearValue[0]" />
                <div>&nbsp;-&nbsp;</div>
                <input @keyup="maxYearValueChange(yearValue[1])"
                       class="flex-grow min-w-0 text-right border px-2 py-1 rounded"
                       name="max_year"
                       v-model="yearValue[1]" />
              </div>
            </div>
            <vue-slider :step="1"
                        :min="2008"
                        :max="2022"
                        v-model="yearValue"></vue-slider>
            <div class="flex flex-row justify-between">
              <label>2008</label>
              <label>2022</label>
            </div>
          </div>
        </div>

        <!-- Budget Range -->
        <div v-if="selectedFilterMenu == 'Budget'"
             id="bid-range-filter"
             class="flex flex-col w-full p-4 py-8 text-black-lighter">
          <div class="rounded-full bg-gray-100 w-full p-1 flex items-center mb-2 mt-4 relative">
            <div class="absolute w-1/2 bottom-0 top-0 p-1 transition-all flex"
                 :class="{ 'left-1/2': isPrice, 'left-0': !isPrice}">
              <div class="bg-white rounded-full shadow-sm w-full h-full"></div>
            </div>
            <div @click="togglePrice"
                 class="w-1/2 text-center py-2 text-sm relative cursor-pointer"
                 :class="{ 'text-blue':isPrice }">
              Finance
            </div>
            <div @click="togglePrice"
                 class="py-2 w-1/2 text-sm text-center relative cursor-pointer"
                 :class="{ 'text-blue':!isPrice }">
              Cash
            </div>
          </div>
          <div v-if="isPrice"
               class="relative select-wrapper w-full flex flex-col bg-white mb-3 text-sm">
            <label class="uppercase text-xs mb-2 text-black-lighter mt-2">Your Budget</label>
            <div class="flex flex-row w-full justify-between items-center mb-2">
              <div class="flex flex-row items-center flex-grow-0 w-full">
                <div class="relative flex-grow min-w-0">
                  <div class="absolute left-0 top-0 bottom-0 flex items-center justify-center pl-2">$</div>
                  <input class="border w-full pr-2 py-1 pl-4 rounded text-right"
                         @keyup="minValueChange(value[0])"
                         name="min_budget"
                         v-model="value[0]" />
                </div>
                <div>&nbsp;-&nbsp;</div>
                <div class="relative flex-grow min-w-0">
                  <div class="absolute left-0 top-0 bottom-0 flex items-center justify-center pl-2">$</div>
                  <input class="border w-full pr-2 py-1 pl-4 rounded text-right"
                         @keyup="maxValueChange(value[1])"
                         name="max_budget"
                         v-model="value[1]" />
                </div>
              </div>
            </div>
            <vue-slider :interval="500"
                        :min="1000"
                        :max="200000"
                        v-model="value"></vue-slider>
            <div class="flex flex-row justify-between">
              <label>$1,000</label>
              <label>$200,000</label>
            </div>
          </div>
          <div v-if="!isPrice"
               class="relative select-wrapper w-full flex flex-col bg-white mb-3 text-sm">
            <label class="uppercase text-xs mb-2 text-black-lighter mt-2">Monthly Budget</label>
            <div class="flex flex-row w-full justify-between items-center mb-2">
              <div class="flex flex-row items-center flex-grow-0 w-full">
                <div class="relative flex-grow">
                  <div class="absolute left-0 top-0 bottom-0 flex items-center justify-center pl-2">$</div>
                  <input class="w-full border pr-2 py-1 pl-4 rounded text-right"
                         @keyup="minEmiValueChange(emiValue[0])"
                         name="min_emi_budget"
                         v-model="emiValue[0]" />
                </div>
                <div>&nbsp;-&nbsp;</div>
                <div class="relative flex-grow">
                  <div class="absolute left-0 top-0 bottom-0 flex items-center justify-center pl-2">$</div>
                  <input class="w-full border pr-2 py-1 pl-4 rounded text-right"
                         @keyup="maxEmiValueChange(emiValue[1])"
                         name="max_emi_budget"
                         v-model="emiValue[1]" />
                </div>
              </div>
            </div>
            <vue-slider :interval="100"
                        :min="0"
                        :max="2000"
                        v-model="emiValue"></vue-slider>
            <div class="flex flex-row justify-between">
              <label>$0</label>
              <label>$2,000</label>
            </div>
          </div>
        </div>


        <!-- Odometer -->
        <div v-if="selectedFilterMenu == 'Odometer'"
             id="bid-range-filter"
             class="flex flex-col w-full p-4 py-8 text-black-lighter">
          <label class="text-base mb-2 mt-8 text-black-lighter">
            Choose Range Below
          </label>
          <div class="relative select-wrapper w-full flex flex-col bg-white mb-3 text-sm">
            <div class="flex flex-row w-full justify-end items-center mb-2">
              <div class="flex flex-row items-center flex-grow-0 w-full">
                <input @keyup="minOdoValueChange(odometerValue[0])"
                       class="flex-grow min-w-0 text-right border px-2 py-1 rounded"
                       name="min_budget"
                       v-model="odometerValue[0]" />
                <div>&nbsp;-&nbsp;</div>
                <input @keyup="maxOdoValueChange(odometerValue[0])"
                       class="flex-grow min-w-0 text-right border px-2 py-1 rounded"
                       name="max_budget"
                       v-model="odometerValue[1]" />
              </div>
            </div>
            <vue-slider :interval="1000"
                        :min="0"
                        :max="100000"
                        v-model="odometerValue"></vue-slider>
            <div class="flex flex-row justify-between">
              <label>0 mi</label>
              <label>100,000 mi</label>
            </div>
          </div>
        </div>

        <!-- Body Types -->
        <div v-if="selectedFilterMenu == 'Body Style'"
             id="bid-range-filter"
             class="flex flex-col w-full p-4 py-8 text-black-lighter">
          <div v-for="(item, index) in filters['bodyStyle']"
               :key="index"
               class="py-4">
            <label class="text-base flex flex-row items-center"><input type="radio"
                     :checked="params['bodystyle'] == item['value']"
                     name="bodystyle"
                     v-on:change="paramSelected('bodystyle',$event.target.value)"
                     class="mr-3 w-4 h-4"
                     :value="item['value']" />
              {{ item['label'] }}</label>
          </div>
        </div>

        <!-- Transmission Types -->
        <div v-if="selectedFilterMenu == 'Transmission'"
             id="bid-range-filter"
             class="flex flex-col w-full p-4 py-8 text-black-lighter">
          <div v-for="(item, index) in filters['transmission']"
               :key="index"
               class="py-4">
            <label class="text-base flex flex-row items-center"><input type="radio"
                     :checked="params['transmission'] == item['value']"
                     name="transmission"
                     v-on:change="paramSelected('transmission',$event.target.value)"
                     class="mr-3 w-4 h-4"
                     :value="item['value']" />
              {{ item['label'] }}</label>
          </div>
        </div>

        <!-- Fuel Types -->
        <div v-if="selectedFilterMenu == 'Fuel Type'"
             id="bid-range-filter"
             class="flex flex-col w-full p-4 py-8 text-black-lighter">
          <div v-for="(item, index) in filters['fuelType']"
               :key="index"
               class="py-4">
            <label class="text-base flex flex-row items-center"><input type="radio"
                     :checked="params['fuel_type'] == item['value']"
                     name="transmission"
                     v-on:change="paramSelected('fuel_type',$event.target.value)"
                     class="mr-3 w-4 h-4"
                     :value="item['value']" />
              {{ item['label'] }}</label>
          </div>
        </div>

        <!-- Distance Range -->
        <div v-if="selectedFilterMenu == 'Distance'"
             id="bid-range-filter"
             class="flex flex-col w-full p-4 py-8 text-black-lighter">
          <div class="relative w-full mb-4">
            <input placeholder="Zip Code"
                   class="border p-3 w-full flex-grow"
                   type="text"
                   name="search"
                   id="search-box" />
            <div class="absolute right-0 top-0 bottom-0 flex items-center flex-row mr-3">
              <i @click="locateMe"
                 v-if="!gettingLocation"
                 class="icon small location"></i>
              <p v-if="gettingLocation">Locating..</p>
            </div>
          </div>
          <label class="text-base mb-2 mt-8 text-black-lighter">
            Choose Range Below
          </label>
          <div v-for="(item, index) in filters['range']"
               :key="index"
               class="py-4">
            <label class="text-base flex flex-row items-center"><input type="radio"
                     :checked="params['distance_range'] == item['value']"
                     name="distance_range"
                     v-on:change="paramSelected('distance_range',$event.target.value)"
                     class="mr-3 w-4 h-4"
                     :value="item['value']" />
              {{ item['label'] }}</label>
          </div>
        </div>
        <!-- Color -->
        <div v-if="selectedFilterMenu == 'Color'"
             id="bid-range-filter"
             class="flex flex-col w-full p-4 py-8 text-black-lighter">
          <label class="uppercase text-xs mb-2 text-black-lighter">
            Select Color
          </label>
          <div v-for="(item, index) in filters['color']"
               :key="index"
               class="py-4">
            <label class="text-base flex flex-row items-center"><input type="radio"
                     :checked="params['color'] == item['value']"
                     name="color"
                     v-on:change="paramSelected('color',$event.target.value)"
                     class="mr-3 w-4 h-4"
                     :value="item['value']" />
              {{ item['label'] }}</label>
          </div>
          <div class="grid grid-rows-3 grid-cols-4 gap-3 mb-6">
            <div v-for="(item, index) in colors"
                 :key="index"
                 :class="colors[index]"
                 class="border cursor-pointer w-12 h-12 rounded"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="shadow flex p-4 justify-between z-10 relative">
      <div class="flex flex-col">
        <div class="text-xl font-semibold">319</div>
        <div class="text-black-lighter">Cars Found</div>
      </div>
      <button type="button"
              @click="gotoSearch"
              class="bg-blue text-white px-4 py-3 text-sm uppercase">
        Apply Filter
      </button>
    </div>
  </div>
</template>
<script>
import VueSlider from "vue-slider-component";
import { mapGetters } from "vuex";
export default {
  props: {
    visible: Boolean,
  },
  name: "Search Result Section",
  data() {
    return {
      selectedFilterMenu: 0,
      value: [5000, 8000],
      yearValue: [2010, 2018],
      odometerValue: [1000, 50000],
      emiValue: [0, 2000],
      isPrice: true,
      distanceValue: 25,
      colors: [
        "bg-white",
        "bg-black-light",
        "bg-black-lighter",
        "bg-gray-600",
        "bg-blue-dark",
        "bg-red-800",
        "bg-brown-light",
        "bg-orange",
        "bg-green-600",
        "bg-brown",
        "bg-yellow-300",
      ],
      filterMenus: [
        "Categories",
        "Make",
        "Model",
        "Year",
        "Budget",
        // "Title",
        "Odometer",
        // "Body Style",
        // "Transmission",
        // "Fuel Type",
        // "Distance",
        // "Color",
      ],
      budgetRanges: [
        "Below $8500",
        "$10,000 to $20,000",
        "$20,000 to $30,000",
        "$30,000 to $40,000",
        "$40,000 to $50,000",
        "Above $50,000",
      ],
      odometers: [
        "Below 1000 miles",
        "1000 to 5000 miles",
        "5000 to 20,000 miles",
        "20,000 to 40,000 miles",
        "Above 40,000 miles",
      ],
      years: [
        "2020 & Above",
        "2018 & Above",
        "2016 & Above",
        "2014 & Above",
        "2010 & Above",
        "Below 2010",
      ],
      makes: [],
      models: [],
      series: [],
      gettingLocation: false,
    };
  },
  computed: {
    ...mapGetters({
      makeObjects: "makes",
      params: "searchParams",
      defaultParams: "defaultParams",
      filters: "filters",
    }),
  },
  watch: {
    params(value) {
      this.setParams(value);
    },
    makeObjects(){
      this.populateMakes();
    }
  },
  mounted() {
    this.populateMakes();
    this.setParams(this.params);
  },
  methods: {
    clearAll() {
      this.filterKeys = [];
      this.closeFilter();
      this.$store.dispatch("fetchDeals", {
        ...this.defaultParams,
      });
    },
    togglePrice() {
      this.isPrice = !this.isPrice;
      this.gotoSearch();
    },
    minValueChange(value) {
      if (value < 1000 || isNaN(value)) {
        this.value[0] = 1000;
      }
      this.value = [...this.value];
      this.gotoSearch();
    },
    maxValueChange(value) {
      if (value > 200000 || isNaN(value)) {
        this.value[1] = 200000;
      }
      this.value = [...this.value];
      this.gotoSearch();
    },
    minEmiValueChange(value) {
      if (value < 1000 || isNaN(value)) {
        this.emiValue[0] = 0;
      }
      this.emiValue = [...this.emiValue];
      this.gotoSearch();
    },
    maxEmiValueChange(value) {
      if (value > 200000 || isNaN(value)) {
        this.emiValue[1] = 2000;
      }
      this.emiValue = [...this.emiValue];
      this.gotoSearch();
    },
    minYearValueChange(value) {
      if (value < 2008 || isNaN(value)) {
        this.yearValue[0] = 2008;
      }
      this.gotoSearch();
    },
    maxYearValueChange(value) {
      if (value > 2022 || isNaN(value)) {
        this.yearValue[1] = 2022;
      }
      this.gotoSearch();
    },
    minOdoValueChange(value) {
      if (value < 0 || isNaN(value)) {
        this.odometerValue[0] = 0;
      }
      this.gotoSearch();
    },
    maxOdoValueChange(value) {
      if (value > 100000 || isNaN(value)) {
        this.odometerValue[1] = 100000;
      }
      this.gotoSearch();
    },

    saveSearch(){
      this.params["min_budget"] = this.value[0];
      this.params["max_budget"] = this.value[1];
      this.params["from_year"] = this.yearValue[0];
      this.params["to_year"] = this.yearValue[1];
      this.params["odometer_min"] = this.odometerValue[0];
      this.params["odometer_max"] = this.odometerValue[1];
      this.params["min_emi"] = this.emiValue[0];
      this.params["max_emi"] = this.emiValue[1];
      this.params["is_price"] = this.isPrice;

      this.$store.dispatch("saveSearch", this.params).then(
          (value) => {
            console.log(value);
          },
          (error) => {
            let message = "Sorry! Something went wrong";
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message = error.response.data.message;
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
              message = error.message;
            }
            this.toast.error(message);
            console.log(error);
          }
      );

    },

    gotoSearch() {
      this.params["min_budget"] = this.value[0];
      this.params["max_budget"] = this.value[1];
      this.params["from_year"] = this.yearValue[0];
      this.params["to_year"] = this.yearValue[1];
      this.params["odometer_min"] = this.odometerValue[0];
      this.params["odometer_max"] = this.odometerValue[1];
      this.params["min_emi"] = this.emiValue[0];
      this.params["max_emi"] = this.emiValue[1];
      this.params["is_price"] = this.isPrice;

      this.closeFilter();
      this.loadDeals(this.params);
      if (this.$route.name != "Search") {
        this.$router.push("/search");
      }
    },
    loadDeals(params) {
      this.$store.dispatch("fetchDeals", params);
    },
    setParams(value) {
      console.log(value);
      if (value["make"]) {
        this.modelsForMake(value["make"]);
      }
      if (value["model"]) {
        this.seriesForModel(value["model"]);
      }
      if (value["series"]) {
        this.seriesSelected(value["series"]);
      }

      if (!this.value) {
        this.value = [1000, 100000];
      }

      if (value["min_budget"] && value["max_budget"]) {
        this.value = [value["min_budget"], value["max_budget"]];
      }

      if (!this.yearValue) {
        this.yearValue = [2008, 2021];
      }

      if (value["from_year"] && value["to_year"]) {
        this.yearValue = [value["from_year"], value["to_year"]];
      }

      if (!this.odometerValue) {
        this.odometerValue = [1000, 50000];
      }
      if (value["odometer_min"] && value["odometer_max"]) {
        this.odometerValue = [value["odometer_min"], value["odometer_max"]];
      }
      if (!this.emiValue) {
        this.emiValue = [0, 2000];
      }
      if (value["min_emi"] >= 0 && value["max_emi"]) {
        this.emiValue = [value["min_emi"], value["max_emi"]];
      }

      // console.log(this.emiValue, [value["min_emi"], value["max_emi"]]);

      this.isPrice = value["is_price"];
    },
    setSelectedFilterMenu(index) {
      this.selectedFilterMenu = index;
    },
    closeFilter() {
      this.$emit("closeFilter", false);
    },
    async getLocation() {
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error("Geolocation is not available."));
        }

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos);
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    async locateMe() {
      this.gettingLocation = true;
      try {
        this.gettingLocation = false;
        this.location = await this.getLocation();
      } catch (e) {
        this.gettingLocation = false;
        this.errorStr = e.message;
      }
    },
    modelsForMake(makeName) {
      this.params.make = makeName;
      this.models = [];
      this.series = [];
      if (!makeName) {
        return;
      }
      const makeObj = this.makeObjects.find(
        (item) => item.name == this.params.make
      );
      this.models = makeObj.models.map((item) => item.name);
    },
    seriesForModel(modelName) {
      this.params.model = modelName;
      this.series = [];
      if (!modelName) {
        return;
      }
      const makeObj = this.makeObjects.find(
        (item) => item.name == this.params.make
      );
      const model = makeObj.models.find(
        (item) => item.name == this.params.model
      );
      this.series = model.series.map((item) => item.name);
    },
    seriesSelected(seriesName) {
      this.params.series = seriesName;
    },
    odometerSelected(odometer) {
      this.params.odometer = odometer;
    },
    paramSelected(key, value) {
      this.params[key] = value;
    },

    selectedCategory(category) {
      this.params.car_category = category;
      this.params.make = "";
      this.params.model = "";
      this.params.series = "";
      this.$store.dispatch("setSearchParams", {
        ...this.params,
      });
      this.gotoSearch();
    },

    updateMake(make) {
      this.params.make = make;
      this.params.model = "";
      this.params.series = "";
      this.$store.dispatch("setSearchParams", {
        ...this.params,
      });
      this.gotoSearch();
    },
    updateModel(model) {
      this.params.model = model;
      this.params.series = "";
      this.$store.dispatch("setSearchParams", {
        ...this.params,
      });
      this.gotoSearch();
    },
    updateSeries(series) {
      this.params.series = series;
      this.$store.dispatch("setSearchParams", {
        ...this.params,
      });
    },
    populateMakes(){
      this.makes = this.makeObjects.map((item) => item.name);
    }
  },
  components: {
    VueSlider,
  },
};
</script>
<style scoped></style>